import React from 'react'
import logo from '../assets/effem-logo.svg';
import { NavLink, Link, useLocation } from 'react-router-dom';

const Nav = () => {
  let location = useLocation();
  let hoverGradientStyle = `hover:text-gradient hover:from-purple-600 hover:to-pink-600`
  return (
    <header className='backdrop-blur-lg bg-black/60 h-20 sticky top-0 z-10 max-md:bg-black max-md:h-[90px]'>
    <div className="flex items-center justify-between px-4 py-0 max-w-[1300px] h-full mx-auto my-0">
      <Link to="/">
        <img src={logo} className="w-auto h-7" alt="Effem Logo" />
      </Link>
      <input type="checkbox" id="menuToggle" className="hidden menu-toggle peer/menu-toggle" />
      <label htmlFor="menuToggle" className="hamburger-menu hidden w-6 h-5 flex-col justify-between cursor-pointer max-md:flex peer-checked/menu-toggle:before:-rotate-45 peer-checked/menu-toggle:after:rotate-45 before:block before:w-full before:h-[2px] before:origin-right before:bg-gray-500 before:transition before:duration-300 before:ease-in-out after:block after:w-full after:h-[2px] after:origin-right after:bg-gray-500 after:transition after:duration-300 after:ease-in-out"></label>

      <nav className='w-full flex justify-between items-center ml-16 transition-opacity duration-300 ease-in-out max-md:h-mobile-nav-height max-md:fixed max-md:flex-col max-md:justify-start max-md:bg-black max-md:py-0 max-md:ml-0 max-md:invisible max-md:opacity-0 max-md:top-[90px] max-md:left-0 max-md:px-3 peer-checked/menu-toggle:flex peer-checked/menu-toggle:opacity-100 peer-checked/menu-toggle:visible'>
        <ul className="flex gap-5 p-0 m-0 list-none nav-left max-md:flex-col max-md:w-full max-md:gap-0">
          <li className='max-md:w-full max-md:border-b max-md:border-gray-500'><NavLink to="/about" className={`py-3.5 px-0 no-underline ${location.pathname === '/about' ?  `text-gradient from-purple-600 to-pink-600 ${hoverGradientStyle}` : `text-gray-500 hover:text-white`} transition-colors duration-300 ease-in-out max-md:block`}>About</NavLink></li>
          <li className='max-md:w-full max-md:border-b max-md:border-gray-500'><NavLink to="/contact" className={`py-3.5 px-0 no-underline ${location.pathname === '/contact' ?  `text-gradient from-purple-600 to-pink-600 ${hoverGradientStyle}` : `text-gray-500 hover:text-white`} transition-colors duration-300 ease-in-out max-md:block`}>Contact</NavLink></li>
          <li className='max-md:w-full max-md:border-b max-md:border-gray-500'>
            <a 
              href="https://podcastindex.org" 
              className='py-3.5 px-0 no-underline text-gray-500 hover:text-white transition-colors duration-300 ease-in-out max-md:block'
              target="_blank" 
              rel="noopener noreferrer"
            >
              Podcast Index
          </a>
        </li>
        <li className="max-md:w-full max-md:border-b max-md:border-gray-500">
            <a 
              href="https://sparrowtek.com" 
              target="_blank" 
              rel="noopener noreferrer" 
              className={`py-3.5 px-0 no-underline text-gray-500 hover:text-white transition-colors duration-300 ease-in-out max-md:block`}
            >
              SparrowTek
            </a>
        </li>
        </ul>
      </nav>
    </div>
  </header>  )
}

export default Nav