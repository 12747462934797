import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/effem-logo.svg';
import XIcon from '../assets/x-icon.svg';
import InstagramIcon from '../assets/instagram-icon.svg';
import GithubIcon from '../assets/github-icon.svg';

const Footer = () => {
    const [year, setYear] = useState(new Date().getFullYear());

    useEffect(() => {
        setYear(new Date().getFullYear());
    } ,[]);

    return (
        <footer className="flex flex-col items-center gap-5 px-5 text-gray-500 border-t border-gray-700 py-14">
            <img src={logo} className="h-8" alt="Effem Logo" />
            <div className="flex flex-wrap justify-center gap-16 m-10">
                <Link to="/about" className='transition-colors duration-300 hover:text-white'>About</Link>
                <Link to="/contact" className='transition-colors duration-300 hover:text-white'>Contact</Link>
                <a href="https://podcastindex.org" className='transition-colors duration-300 hover:text-white' target="_blank" rel="noopener noreferrer">Podcast Index</a>
                <a href="https://sparrowtek.com" target="_blank" rel="noopener noreferrer" className="transition-colors duration-300 hover:text-white"> SparrowTek</a>
            </div>
            <div className="text-[12px]">
                Copyright &copy; {year} Effem. All rights reserved. Jersey City, NJ.
            </div>
            <div className="flex gap-5 mt-2">
                <a href="https://www.x.com/EffemXYZ" target="_blank" rel="noreferrer">
                    <img src={XIcon} className="h-5" alt="X Icon" />
                </a>
                <a href="https://njump.me/npub1kl5mhnm9yjyv20dqrp5um4g722g2h0ydqr2wd30ldu0vannqfarsdj44l0" target="_blank" rel="noreferrer">
                    <div className='text-white'>
                        NOSTR
                    </div>
                </a>
                <a href="https://www.instagram.com/effemxyz/" target="_blank" rel="noreferrer">
                    <img src={InstagramIcon} className="h-5" alt="Instagram Icon" />
                </a>
                <a href="https://github.com/effemxyz" target="_blank" rel="noreferrer">
                    <img src={GithubIcon} className="h-5" alt="Github Icon" />
                </a>
            </div>
        </footer>
    );
}

export default Footer;
